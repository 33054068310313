import React, { useState } from 'react';
import styled from 'styled-components';
const Tab = styled.button `
  font-size: 20px;
  padding: 10px 60px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  ${(props) => props.active &&
    `
    border-bottom: 2px solid black;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div `
  display: flex;
`;
export function tabGroup(props) {
    const [active, setActive] = useState(props.types[0]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ButtonGroup, null, props.types.map(type => (React.createElement(Tab, { key: type, active: active === type, onClick: () => {
                props.setType(type);
                setActive(type);
            } }, type))))));
}
