var ResponseType;
(function (ResponseType) {
    ResponseType["ERROR"] = "ERROR";
    ResponseType["OK"] = "OK";
})(ResponseType || (ResponseType = {}));
export class Result {
    constructor(value, type) {
        this._value = value;
        this._type = type;
    }
    static error(value) {
        return new Result(value, ResponseType.ERROR);
    }
    static errorMsg(message) {
        return new Result(new Error(message), ResponseType.ERROR);
    }
    static ok(value) {
        return new Result(value, ResponseType.OK);
    }
    isError() {
        return this._type === ResponseType.ERROR;
    }
    isOk() {
        return this._type === ResponseType.OK;
    }
    get value() {
        return this._value;
    }
    getOk() {
        return this._value;
    }
    castError() {
        return this;
    }
    getErrorMessage() {
        if (this.isError()) {
            return this._value.message;
        }
        return '';
    }
}
export function isOk(result) {
    return result.isOk();
}
export function isError(result) {
    return result.isError();
}
