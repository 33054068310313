var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isOk } from '../lib/result';
import { httpJsonGet, ApiPath } from '../lib/http';
import { listDevices } from './device';
var DrmScheme;
(function (DrmScheme) {
    DrmScheme["fairPlay"] = "FairPlay";
    DrmScheme["widevine"] = "Widevine";
})(DrmScheme || (DrmScheme = {}));
var MarketStub;
(function (MarketStub) {
    MarketStub["nsw"] = "nsw";
    MarketStub["vic"] = "vic";
    MarketStub["sa"] = "sa";
    MarketStub["qld"] = "qld";
    MarketStub["wa"] = "wa";
})(MarketStub || (MarketStub = {}));
const defaultDevice = {
    id: 0,
    name: 'web',
    enabledSsaiForChannels: false,
    enabledSsaiForLiveEvents: false,
    drmEnabled: false,
    fullHdEnabled: false,
    startOverEnabled: false,
    createdAt: '',
    updatedAt: '',
    cdn: {
        Akamai: { activated: false, weighting: 100 },
        CloudFront: { activated: false, weighting: 100 },
    },
    tokenisationEnabled: false,
};
const getDeviceWithTogglesOn = () => __awaiter(void 0, void 0, void 0, function* () {
    const devices = yield listDevices();
    let fallback = defaultDevice;
    if (isOk(devices)) {
        for (const device of devices._value.rows) {
            if (device.startOverEnabled && !device.drmEnabled) {
                if (device.fullHdEnabled && device.enabledSsaiForChannels)
                    return device;
                fallback = device;
            }
        }
    }
    return fallback;
});
const getMarket = (device, marketStub, drmScheme, fullHdEnabled) => __awaiter(void 0, void 0, void 0, function* () {
    return httpJsonGet(ApiPath.markets, { device, clientConfig: JSON.stringify({ drmScheme, fullHdEnabled }) }, marketStub);
});
export const listStartOver = () => __awaiter(void 0, void 0, void 0, function* () {
    const device = yield getDeviceWithTogglesOn();
    const promiseList = [];
    for (const fullHdEnabled of [true, false]) {
        if (fullHdEnabled && !device.fullHdEnabled)
            continue;
        for (const drmScheme of Object.values(DrmScheme)) {
            // no legacy Dash streams, so skip that combination
            if (!fullHdEnabled && drmScheme === DrmScheme.widevine)
                continue;
            for (const marketStub of Object.values(MarketStub)) {
                promiseList.push(getMarket(device.name, marketStub, drmScheme, fullHdEnabled));
            }
        }
    }
    return Promise.all(promiseList);
});
