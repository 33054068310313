import { httpJsonGet, AdminPath } from '../lib/http';
export const getDrmEnabled = (device) => device.drmEnabled;
export const getStartOverEnabled = (device) => device.startOverEnabled;
export const getEnabledSsaiForChannels = (device) => device.enabledSsaiForChannels;
export const getEnabledSsaiForLiveEvents = (device) => device.enabledSsaiForLiveEvents;
export const getFullHdEnabled = (device) => device.fullHdEnabled;
export const getAkamaiCDNEnabled = (device) => device.cdn.Akamai.activated;
export const getCloudFrontCDNEnabled = (device) => device.cdn.CloudFront.activated;
export const getTokenisationEnabled = (device) => device.tokenisationEnabled;
export function listDevices() {
    return httpJsonGet(AdminPath.devices);
}
