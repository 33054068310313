import React from 'react';
import { render } from 'react-dom';
import { Main } from '@components/main';
const initialise = () => {
    console.log('app is initialised...');
    const domNode = document.getElementById('application-entry');
    if (domNode) {
        render(React.createElement(Main, null), domNode);
        return;
    }
    console.error('application-entry not found');
};
initialise();
