var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Result } from './result';
var Method;
(function (Method) {
    Method["GET"] = "GET";
    Method["POST"] = "POST";
    Method["PUT"] = "PUT";
    Method["PATCH"] = "PATCH";
    Method["DELETE"] = "DELETE";
})(Method || (Method = {}));
export var AdminPath;
(function (AdminPath) {
    AdminPath["devices"] = "devices";
    AdminPath["channels"] = "channels";
})(AdminPath || (AdminPath = {}));
export var ApiPath;
(function (ApiPath) {
    ApiPath["markets"] = "live/tv/markets";
})(ApiPath || (ApiPath = {}));
const getAdminUrl = (path) => `https://${config.apiEndpoint}/api/admin/${path}`;
const getApiUrl = (path, endpoint) => `https://${config.apiEndpoint}/api/v1/${path}${endpoint ? `/${endpoint}` : ''}`;
const getUrl = (path, endpoint) => {
    if (((vmsPath) => Object.values(AdminPath).includes(vmsPath))(path))
        return getAdminUrl(path);
    if (((vmsPath) => Object.values(ApiPath).includes(vmsPath))(path))
        return getApiUrl(path, endpoint);
    throw new Error(`Invalid path: ${path}`);
};
function getOptions(method, payload) {
    const options = {
        method,
        cache: 'no-store',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    if (typeof payload !== 'undefined') {
        options.body = JSON.stringify(payload);
    }
    return options;
}
const appendQueryString = (url, queryParams) => {
    for (const property in queryParams)
        url.searchParams.append(property, queryParams[property]);
    return url;
};
function httpJsonApi(_a) {
    return __awaiter(this, arguments, void 0, function* ({ path, method, payload, queryParams, endpoint, }) {
        const url = new URL(getUrl(path, endpoint));
        if (queryParams)
            appendQueryString(url, queryParams);
        try {
            const response = yield self.fetch(url.toString(), getOptions(method, payload));
            if (!response.ok) {
                // check if there is a message with the error
                const responseJson = yield response.json();
                return Result.error(new Error(responseJson.message ? responseJson.message : response.statusText));
            }
            const responsePayload = response.status === 204 ? undefined : yield response.json();
            return Result.ok(responsePayload);
        }
        catch (e) {
            return Result.error(e);
        }
    });
}
export function httpJsonGet(path, queryParams, endpoint) {
    return __awaiter(this, void 0, void 0, function* () {
        return httpJsonApi({ path, method: Method.GET, queryParams, endpoint });
    });
}
export function httpJsonPost(path, payload) {
    return __awaiter(this, void 0, void 0, function* () {
        return httpJsonApi({ path, method: Method.POST, payload });
    });
}
export function httpJsonPut(path, payload) {
    return __awaiter(this, void 0, void 0, function* () {
        return httpJsonApi({ path, method: Method.PUT, payload });
    });
}
export function httpJsonPatch(path, payload) {
    return __awaiter(this, void 0, void 0, function* () {
        return httpJsonApi({ path, method: Method.PATCH, payload });
    });
}
export function httpJsonDelete(path) {
    return __awaiter(this, void 0, void 0, function* () {
        return httpJsonApi({ path, method: Method.DELETE });
    });
}
