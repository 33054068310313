import React, { useState } from 'react';
import { devicesTable } from './devices';
import { channelsTable } from './channels';
import { startOverTable } from './start-over';
import { tabGroup } from './tabs';
export const Main = () => {
    const [selection, setSelection] = useState('channels');
    const ChannelsTable = channelsTable();
    const DevicesTable = devicesTable();
    const StartOverTable = startOverTable();
    return (React.createElement(React.Fragment, null,
        tabGroup({
            types: ['channels', 'devices', 'start over'],
            setType: setSelection,
        }),
        selection === 'channels' ? ChannelsTable : selection === 'devices' ? DevicesTable : selection === 'start over' ? StartOverTable : null));
};
