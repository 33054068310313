var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { getAkamaiCDNEnabled, getCloudFrontCDNEnabled, getDrmEnabled, getEnabledSsaiForChannels, getEnabledSsaiForLiveEvents, getFullHdEnabled, getStartOverEnabled, getTokenisationEnabled, listDevices } from '../models/device';
import { isOk } from '../lib/result';
import { Table, TableHead, TableHeadCell, TableHeadRow, TableRowEven, TableRowOdd, toggleTableCell } from './styled-table';
export function devicesTable() {
    const [data, setData] = useState({ count: 0, rows: [] });
    const loadDevices = () => __awaiter(this, void 0, void 0, function* () {
        const devices = yield listDevices();
        if (isOk(devices)) {
            setData(devices._value);
        }
    });
    useEffect(() => {
        loadDevices();
    }, []);
    const tableRow = (device, index) => {
        const cells = React.createElement(React.Fragment, null,
            React.createElement(TableHeadCell, null, device.name),
            toggleTableCell(getEnabledSsaiForChannels(device)),
            toggleTableCell(getEnabledSsaiForLiveEvents(device)),
            toggleTableCell(getDrmEnabled(device)),
            toggleTableCell(getFullHdEnabled(device)),
            toggleTableCell(getStartOverEnabled(device)),
            toggleTableCell(getAkamaiCDNEnabled(device)),
            toggleTableCell(getCloudFrontCDNEnabled(device)),
            toggleTableCell(getTokenisationEnabled(device)));
        return index % 2 === 0 ? React.createElement(TableRowEven, null, cells) : React.createElement(TableRowOdd, null, cells);
    };
    return (React.createElement(Table, null,
        React.createElement(TableHead, null,
            React.createElement(TableHeadRow, null,
                React.createElement(TableHeadCell, null, "Device"),
                React.createElement(TableHeadCell, null, "Ad Insertion Enabled for Channels"),
                React.createElement(TableHeadCell, null, "Ad Insertion Enabled for Live Events"),
                React.createElement(TableHeadCell, null, "DRM Enabled"),
                React.createElement(TableHeadCell, null, "Full HD Enabled"),
                React.createElement(TableHeadCell, null, "Start Over Enabled"),
                React.createElement(TableHeadCell, null, "CDN - Akamai Enabled"),
                React.createElement(TableHeadCell, null, "CDN - CloudFront Enabled"),
                React.createElement(TableHeadCell, null, "Tokenised"))),
        React.createElement("tbody", null, data.rows.map((device, i) => tableRow(device, i)))));
}
