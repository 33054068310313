import styled from 'styled-components';
import React from 'react';
export const Table = styled.table `
  width:100%;
  border-collapse: collapse;
  font-family: Arial, Helvetica, sans-serif;`;
export const TableHead = styled.thead `
  font-size: 0.875rem;
	text-transform: uppercase;
	letter-spacing: 2%;`;
export const TableHeadCell = styled.th `
  color: white;
  text-align: left;
  padding: 8px;
  background-color: rgba(100, 99, 199, 0.6);`;
export const TableHeadRow = styled.tr `
  background-color: rgba(100, 99, 199, 1);`;
export const TableRowEven = styled.tr `
  background-color: #f2f2f2;`;
export const TableRowOdd = styled.tr `
  background-color: white;`;
export const TableRowCellOff = styled.td `
  text-align: left;
  padding: 8px;
  background: rgba(255, 99, 71, 0.4);`;
export const TableRowCellOn = styled.td `
  text-align: left;
  padding: 8px;
  background: rgba(100, 190, 71, 0.4);`;
export const toggleTableCell = (toggleValue) => {
    return toggleValue ? React.createElement(TableRowCellOn, null, "Yes") : React.createElement(TableRowCellOff, null, "No");
};
export const textTableCell = (textValue) => {
    return React.createElement(TableRowCellOn, null, textValue);
};
