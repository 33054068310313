var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { listChannels } from '../models/channel';
import { isOk } from '../lib/result';
import { Table, TableHead, TableHeadCell, TableRowCellOn, TableRowCellOff, TableHeadRow, TableRowEven, TableRowOdd, toggleTableCell } from './styled-table';
export function channelsTable() {
    const [data, setData] = useState({ count: 0, channels: [] });
    const loadChannels = () => __awaiter(this, void 0, void 0, function* () {
        const channels = yield listChannels();
        if (isOk(channels)) {
            setData(channels._value);
        }
    });
    useEffect(() => {
        loadChannels();
    }, []);
    const tableRow = (channel, index) => {
        const cells = React.createElement(React.Fragment, null,
            React.createElement(TableHeadCell, null, channel.name),
            channel.activeStream.toUpperCase() === 'PRIMARY' ? React.createElement(TableRowCellOn, null, channel.activeStream) : React.createElement(TableRowCellOff, null, channel.activeStream),
            toggleTableCell(channel.ssaiEnabled),
            toggleTableCell(channel.drmEnabled),
            toggleTableCell(channel.fullHdEnabled),
            toggleTableCell(channel.startOverEnabled),
            toggleTableCell(channel.cdn.Akamai.activated),
            toggleTableCell(channel.cdn.CloudFront.activated),
            toggleTableCell(channel.tokenisation.Akamai.enabled),
            toggleTableCell(channel.tokenisation.CloudFront.enabled));
        return index % 2 === 0 ? React.createElement(TableRowEven, null, cells) : React.createElement(TableRowOdd, null, cells);
    };
    return (React.createElement(Table, null,
        React.createElement(TableHead, null,
            React.createElement(TableHeadRow, null,
                React.createElement(TableHeadCell, null, "Channel"),
                React.createElement(TableHeadCell, null, "Active Stream"),
                React.createElement(TableHeadCell, null, "Ad Insertion Enabled"),
                React.createElement(TableHeadCell, null, "DRM Enabled"),
                React.createElement(TableHeadCell, null, "Full HD Enabled"),
                React.createElement(TableHeadCell, null, "Start Over Enabled"),
                React.createElement(TableHeadCell, null, "CDN - Akamai Enabled"),
                React.createElement(TableHeadCell, null, "CDN - CloudFront Enabled"),
                React.createElement(TableHeadCell, null, "Tokenised - Akamai Enabled"),
                React.createElement(TableHeadCell, null, "Tokenised - CloudFront Enabled"))),
        React.createElement("tbody", null, data.channels.map((channel, i) => tableRow(channel, i)))));
}
