var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { listStartOver } from '../models/start-over';
import { isOk } from '../lib/result';
import { Table, TableHead, TableHeadCell, TableHeadRow, TableRowCellOff, TableRowCellOn, TableRowEven, TableRowOdd } from './styled-table';
export function startOverTable() {
    const [data, setData] = useState([]);
    const loadMarketChannels = () => __awaiter(this, void 0, void 0, function* () {
        const markets = yield listStartOver();
        let newData = [];
        for (const result of markets) {
            if (isOk(result)) {
                newData = newData.concat(result._value.market.channels);
            }
        }
        setData(newData);
    });
    useEffect(() => {
        loadMarketChannels();
    }, []);
    const startOverConfigCell = (startOverConfig) => {
        if (!startOverConfig)
            return React.createElement("p", null, "-");
        if (startOverConfig.programs.length === 0)
            return React.createElement("p", null, "Empty Programs array");
        return (React.createElement(React.Fragment, null, startOverConfig.programs.map((program) => {
            const localDateTimeString = new Date(program.startedAt).toLocaleString("en-US", {
                timeZone: "Australia/Sydney",
                dateStyle: "short",
                timeStyle: "medium"
            });
            return React.createElement("div", null,
                React.createElement("span", null, program.id),
                "\u2003",
                React.createElement("span", null,
                    localDateTimeString,
                    " AEST"),
                "\u2003",
                React.createElement("span", null,
                    "(",
                    program.startedAt,
                    ")"));
        })));
    };
    const tokenisationCdnCell = (stream) => {
        var _a;
        if (stream.needsTokenisation && stream.tokenisationPayload && ((_a = stream.tokenisationPayload) === null || _a === void 0 ? void 0 : _a.cdn)) {
            return stream.tokenisationPayload.cdn;
        }
        return 'N/A';
    };
    const tableRow = (channel, index) => {
        var _a, _b, _c, _d;
        const cells = ((_b = (_a = channel === null || channel === void 0 ? void 0 : channel.stream) === null || _a === void 0 ? void 0 : _a.startOver) === null || _b === void 0 ? void 0 : _b.programs) ? React.createElement(React.Fragment, null,
            React.createElement(TableHeadCell, null, channel.streamSlug),
            React.createElement(TableRowCellOn, null, channel.stream.streamType),
            React.createElement(TableRowCellOn, null, channel.stream.isFullHd ? 'Yes' : 'No'),
            React.createElement(TableRowCellOn, null, channel.stream.needsTokenisation ? 'Yes' : 'No'),
            React.createElement(TableRowCellOn, null, tokenisationCdnCell(channel.stream)),
            React.createElement(TableRowCellOn, null, startOverConfigCell((_c = channel === null || channel === void 0 ? void 0 : channel.stream) === null || _c === void 0 ? void 0 : _c.startOver))) : React.createElement(React.Fragment, null,
            React.createElement(TableHeadCell, null, channel.streamSlug),
            React.createElement(TableRowCellOff, null, channel.stream.streamType),
            React.createElement(TableRowCellOff, null, channel.stream.isFullHd ? 'Yes' : 'No'),
            React.createElement(TableRowCellOn, null, channel.stream.needsTokenisation ? 'Yes' : 'No'),
            React.createElement(TableRowCellOn, null, tokenisationCdnCell(channel.stream)),
            React.createElement(TableRowCellOff, null, startOverConfigCell((_d = channel === null || channel === void 0 ? void 0 : channel.stream) === null || _d === void 0 ? void 0 : _d.startOver)));
        return index % 2 === 0 ? React.createElement(TableRowEven, null, cells) : React.createElement(TableRowOdd, null, cells);
    };
    return (React.createElement(Table, null,
        React.createElement(TableHead, null,
            React.createElement(TableHeadRow, null,
                React.createElement(TableHeadCell, null, "Stream"),
                React.createElement(TableHeadCell, null, "Stream Type"),
                React.createElement(TableHeadCell, null, "Full Hd Enabled"),
                React.createElement(TableHeadCell, null, "Tokenised"),
                React.createElement(TableHeadCell, null, "Tokenisation CDN"),
                React.createElement(TableHeadCell, null, "Start Over - Program Boundaries"))),
        React.createElement("tbody", null, data.map((channel, i) => tableRow(channel, i)))));
}
